<template>
  <el-card class="main" style="margin-top: 10px;">
    <div>
      <div class="itemPut">
        <div style="margin: 20px"></div>
        <el-form
          style="margin-left: 0px"
          :model="ruleForm"
          label-width="100px"
          label-position="right"
          :rules="rules"
          ref="inserform"
        >
          <el-form-item  label="登录账号：" prop="username">
            <el-input  v-model.trim="ruleForm.username"></el-input>
          </el-form-item>
          <el-form-item label="登录密码：" prop="password">
            <el-input
              v-model.trim="ruleForm.password"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="成员姓名：" prop="realname">
            <el-input v-model.trim="ruleForm.realname"></el-input>
          </el-form-item>
          <el-form-item label="手机号码：" prop="mobile">
            <el-input v-model.trim="ruleForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="邮箱地址：" prop="email">
            <el-input v-model.trim="ruleForm.email"></el-input>
          </el-form-item>
          <el-form-item label="成员角色：" prop="roleId">
            <el-select
              style="width: 260px;"
              v-model.trim="ruleForm.roleId"
              placeholder="请选择成员角色"
            >
              <el-option
                v-for="item in rolelist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属部门：" prop="departmentId">
            <el-select   style="width: 260px;" v-model="ruleForm.departmentId" placeholder="请选择部门">
              <el-option
                v-for="item in departmentlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上级名称：" prop="parentId">
            <el-select style="width: 260px;" v-model="ruleForm.parentId" placeholder="请选择部门">
              <el-option
                v-for="(item, index) in sjuser"
                :key="item"
                :label="item"
                :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注信息： " prop="remark">
            <el-input type="textarea" v-model="ruleForm.remark"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <router-link to="/user/list">
            <el-button>取 消</el-button>
          </router-link>
          <el-button
            type="primary"
            style="margin-left: 50px"
            @click="adduser('inserform')"
            >提 交</el-button
          >
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import { userAdd, userSuperiorAll } from "@/api/user";
import { roleList } from "@/api/role";
import { departmentList } from "@/api/department";

export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      ruleForm: {},
      rules: {
        username: [
          { required: true, message: "请输入登录账号", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "6-18位数字或字母组成，不能用特殊符号、表情、空格",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          {
            min: 4,
            max: 12,
            message: "4-12位数字或字母组成，不能用特殊符号、表情、空格",
            trigger: "blur",
          },
          {
            pattern: /^[a-z0-9]{4,12}$/,
            message: "4-12位数字或字母组成，不能用特殊符号、表情、空格",
            trigger: ["blur"],
          },
        ],
        realname: [
          { required: true, message: "请输入成员名称", trigger: "blur" },
          {
            message: "不能用特殊符号、表情、空格",
            trigger: "blur",
          },
        ],
        mobile: [{ required: true, validator: checkPhone, trigger: "blur" }],
        roleId: [
          { required: true, message: "请选择成员角色", trigger: "blur" },
        ],
        departmentId: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
      },
      rolelist: [],
      departmentlist: [],
      sjuser: [],
    };
  },
  created() {
    this.list();
  },

  methods: {
    list() {
      //获取角色
      roleList().then((res) => {
        this.rolelist = res.data.list;
      });
      //获取部门
      departmentList().then((res) => {
        this.departmentlist = res.data.list;
      });
      //上级(选项)列表
      userSuperiorAll().then((res) => {
        this.sjuser = res.data;
      });
    },
    //新建成员
    adduser(inserform) {
      let self = this;
      self.$refs[inserform].validate((res) => {
        if (res) {
          userAdd(this.ruleForm).then(() => {
            this.$router.push("/user/list");
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/overall.less";
/deep/ .main{
  height: 100%;
}
/deep/ .el-input__inner{
  color: #333333;
}
/deep/ input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-transition-delay: 111111s;
        -webkit-transition: color 11111s ease-out, background-color 111111s ease-out;
    }

</style>
